// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Timetracker2 from "../../blocks/timetracker2/src/Timetracker2";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Languageswitch from "../../blocks/languageswitch/src/Languageswitch";
import Surveys2 from "../../blocks/surveys2/src/Surveys2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Customform3 from "../../blocks/customform3/src/Customform3";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Assessmenttest from "../../blocks/assessmenttest/src/Assessmenttest";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfgeneratecredentials from "../../blocks/cfgeneratecredentials/src/Cfgeneratecredentials";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Cfbulkuploadchangerequest from "../../blocks/cfbulkuploadchangerequest/src/Cfbulkuploadchangerequest";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Videoembeddingyoutube from "../../blocks/videoembeddingyoutube/src/Videoembeddingyoutube";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Projectreporting from "../../blocks/projectreporting/src/Projectreporting";
import Cfonlineproctoringcaptureimages from "../../blocks/cfonlineproctoringcaptureimages/src/Cfonlineproctoringcaptureimages";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Scoring from "../../blocks/scoring/src/Scoring";
import Videos from "../../blocks/videos/src/Videos";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Cflanguageuploadsupport from "../../blocks/cflanguageuploadsupport/src/Cflanguageuploadsupport";
import Cfonlineproctoringblocking from "../../blocks/cfonlineproctoringblocking/src/Cfonlineproctoringblocking";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";



const routeMap = {
Timetracker2:{
 component:Timetracker2,
path:"/Timetracker2"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Languageswitch:{
 component:Languageswitch,
path:"/Languageswitch"},
Surveys2:{
 component:Surveys2,
path:"/Surveys2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Customform3:{
 component:Customform3,
path:"/Customform3"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Assessmenttest:{
 component:Assessmenttest,
path:"/Assessmenttest"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Cfgeneratecredentials:{
 component:Cfgeneratecredentials,
path:"/Cfgeneratecredentials"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Cfbulkuploadchangerequest:{
 component:Cfbulkuploadchangerequest,
path:"/Cfbulkuploadchangerequest"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Videoembeddingyoutube:{
 component:Videoembeddingyoutube,
path:"/Videoembeddingyoutube"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Projectreporting:{
 component:Projectreporting,
path:"/Projectreporting"},
Cfonlineproctoringcaptureimages:{
 component:Cfonlineproctoringcaptureimages,
path:"/Cfonlineproctoringcaptureimages"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Scoring:{
 component:Scoring,
path:"/Scoring"},
Videos:{
 component:Videos,
path:"/Videos"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Cflanguageuploadsupport:{
 component:Cflanguageuploadsupport,
path:"/Cflanguageuploadsupport"},
GroupVideoCall:{
 component:GroupVideoCall,
path:"/GroupVideoCall"},
Cfonlineproctoringblocking:{
 component:Cfonlineproctoringblocking,
path:"/Cfonlineproctoringblocking"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},

  Home: {
component:Appointments,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
